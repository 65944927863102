import axios from 'axios';
import { almPlatforms } from '../data/almPlatforms';

export async function fetchClmData(vaults, clms) {
  /// combine both vaults and clms, only include vaults with ids that end in -vault and are not bifi-vault
  const filteredVaults = vaults.filter(vault => vault.id.endsWith('-vault') && vault.id !== 'bifi-vault');
  const combinedVaults = [...filteredVaults, ...clms];
  try {
    return combinedVaults.reduce((acc, vault) => {
      if (vault.status === 'eol') return acc;
      const capitalizedChain = vault.chain.charAt(0).toUpperCase() + vault.chain.slice(1);
      acc[capitalizedChain] = (acc[capitalizedChain] || 0) + 1;
      return acc;
    }, {});
  } catch (error) {
    console.error('Error fetching CLM data:', error);
    return null;
  }
}

export async function fetchTVLData(vaults, clms, tvlData) {
  try {
    const clmChainTVL = {};
    const clmPlatformTVL = {};
    const vaultChainTVL = {};
    const vaultPlatformTVL = {};
    let totalVaultTVL = 0;
    let totalCLMTVL = 0;

    clms.forEach(vault => {
      const chain = vault.chain.charAt(0).toUpperCase() + vault.chain.slice(1);
      const platform = capitalizePlatform(vault.tokenProviderId);
      const id = vault.id;
      const tvl = getTVLForVault(tvlData, id);

      if (tvl) {
        clmChainTVL[chain] = (clmChainTVL[chain] || 0) + tvl;
        clmPlatformTVL[platform] = (clmPlatformTVL[platform] || 0) + tvl;
        totalCLMTVL += tvl;
      }
    });

    vaults.forEach(vault => {
      const chain = vault.network.charAt(0).toUpperCase() + vault.network.slice(1);
      const platform = capitalizePlatform(vault.platformId);
      const id = vault.id;
      if (vault.id.endsWith('-vault') && !vault.id.endsWith('bifi-vault')) return;
      const tvl = getTVLForVault(tvlData, id);

      if (tvl) {
        vaultChainTVL[chain] = (vaultChainTVL[chain] || 0) + tvl;
        vaultPlatformTVL[platform] = (vaultPlatformTVL[platform] || 0) + tvl;
        totalVaultTVL += tvl;
      }
    });

    const bifiPoolTvl = getTVLForVault(tvlData, 'bifi-pool');
    const beVeloTvl = getTVLForVault(tvlData, 'beefy-bevelo-v2-earnings');
    const beQiTvl = getTVLForVault(tvlData, 'beqiv2-pool');
    const beFTMTvl = getTVLForVault(tvlData, 'beefy-beFTM-earnings');

    vaultChainTVL['Ethereum'] = (vaultChainTVL['Ethereum'] || 0) + bifiPoolTvl + beQiTvl;
    vaultChainTVL['Optimism'] = (vaultChainTVL['Optimism'] || 0) + beVeloTvl;
    vaultChainTVL['Fantom'] = (vaultChainTVL['Fantom'] || 0) + beFTMTvl;
    vaultPlatformTVL['Beefy'] = (vaultPlatformTVL['Beefy'] || 0) + bifiPoolTvl + beVeloTvl + beQiTvl + beFTMTvl;
    totalVaultTVL += bifiPoolTvl + beVeloTvl + beQiTvl + beFTMTvl;

    return {
      clmChainTVL,
      clmPlatformTVL,
      vaultChainTVL,
      vaultPlatformTVL,
      totalVaultTVL,
      totalCLMTVL,
    };
  } catch (error) {
    console.error('Error fetching TVL data:', error);
    return {
      clmChainTVL: {},
      clmPlatformTVL: {},
      vaultChainTVL: {},
      vaultPlatformTVL: {},
      totalVaultTVL: 0,
      totalCLMTVL: 0,
    };
  }
}

function capitalizePlatform(platform) {
  const fullCapitalize = ['gmx', 'woo'];

  if (fullCapitalize.includes(platform.toLowerCase())) {
    return platform.toUpperCase();
  }

  return platform
    .split('-')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');
}

function getTVLForVault(tvlData, vaultId) {
  let totalTVL = 0;
  for (const chainId in tvlData) {
    totalTVL += tvlData[chainId][vaultId] || 0;
    totalTVL += tvlData[chainId][`${vaultId}-rp`] || 0;
    totalTVL += tvlData[chainId][`${vaultId}-vault`] || 0;
  }
  return totalTVL;
}

export async function fetchALMTVLData() {
  const almTVLData = {};
  const fetchPromises = Object.values(almPlatforms)
    .filter(platform => platform.key !== 'beefy')
    .map(async platform => {
      try {
        const response = await fetch(platform.apiEndpoint);
        const tvl = await response.json();
        return { key: platform.key, label: platform.label, tvl };
      } catch (error) {
        console.error(`Error fetching TVL for ${platform.label}:`, error);
        return { key: platform.key, label: platform.label, tvl: 0 };
      }
    });

  const results = await Promise.all(fetchPromises);

  results.forEach(result => {
    almTVLData[result.key] = { label: result.label, tvl: result.tvl };
  });

  console.log('Fetched ALM TVL Data:', almTVLData); // Debug log
  return almTVLData;
}

export async function fetchRevenueData(vaults, clms, apyData, tvlData) {
  try {
    const revenueByChain = {};
    const revenueByPlatform = {};
    const revenueByVault = {};
    const clmRevenueByChain = {};
    const clmRevenueByPlatform = {};
    const clmRevenueByVault = {};
    const allVaults = [];
    const allLegacy = [];

    function processVault(vault, isClm = false) {
      const chain = vault.chain.charAt(0).toUpperCase() + vault.chain.slice(1);
      const platform = capitalizePlatform(vault.tokenProviderId || vault.platformId);
      const id = vault.id;
      const tvl = getTVLForVault(tvlData, id);
      const apyInfo = apyData[id] || {};

      let revenue = 0;
      if (isClm) {
        const clmApr = apyInfo.clmApr || 0;
        const vaultApr = apyInfo.vaultApr || 0;
        const rewardPoolApr = apyInfo.rewardPoolTradingApr || 0;

        revenue = tvl * (1.095 * clmApr) * 0.085;
        revenue += tvl * (1.095 * vaultApr) * 0.085;
        revenue += tvl * (1.095 * rewardPoolApr) * 0.085;

        clmRevenueByChain[chain] = (clmRevenueByChain[chain] || 0) + revenue;
        clmRevenueByPlatform[platform] = (clmRevenueByPlatform[platform] || 0) + revenue;
        clmRevenueByVault[id] = revenue;
      } else {
        const vaultApr = apyInfo.vaultApr || 0;
        revenue = tvl * (1.095 * vaultApr) * 0.085;

        revenueByChain[chain] = (revenueByChain[chain] || 0) + revenue;
        revenueByPlatform[platform] = (revenueByPlatform[platform] || 0) + revenue;
        revenueByVault[id] = revenue;
      }

      return {
        id,
        chain,
        platform,
        revenue,
        type: isClm ? 'CLM' : 'Vault',
        tvl,
      };
    }

    clms.forEach(clm => {
      allVaults.push(processVault(clm, true));
    });

    vaults.forEach(vault => {
      if (vault.status !== 'eol' && vault.id !== 'bifi-vault' && !vault.id.endsWith('-vault')) {
        allLegacy.push(processVault(vault));
      }
    });

    const topRevenueIds = Object.entries(clmRevenueByVault)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10)
      .map(([id, revenue]) => {
        const clm = clms.find(v => v.id === id);
        return {
          id,
          chain: clm.chain.charAt(0).toUpperCase() + clm.chain.slice(1),
          platform: capitalizePlatform(clm.tokenProviderId),
          revenue,
        };
      });

    const topVaultRevenueIds = Object.entries(revenueByVault)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10)
      .map(([id, revenue]) => {
        const vault = vaults.find(v => v.id === id);
        return {
          id,
          chain: vault.chain.charAt(0).toUpperCase() + vault.chain.slice(1),
          platform:
            vault.tokenProviderId !== undefined ? capitalizePlatform(vault.tokenProviderId) : '',
          revenue,
        };
      });

    console.log('Final revenue by chain:', revenueByChain);
    console.log('Final revenue by platform:', revenueByPlatform);
    console.log('Top 10 revenue producing IDs:', topRevenueIds);

    return {
      clmRevenueByChain,
      clmRevenueByPlatform,
      revenueByChain,
      revenueByPlatform,
      topRevenueIds,
      topVaultRevenueIds,
      allVaults,
      allLegacy,
    };
  } catch (error) {
    console.error('Error fetching revenue data:', error);
    return {
      clmRevenueByChain: {},
      clmRevenueByPlatform: {},
      revenueByChain: {},
      revenueByPlatform: {},
      topRevenueIds: [],
      topVaultRevenueIds: [],
      allVaults: [],
      allLegacy: [],
    };
  }
}

export async function fetchUserCount(vaults, clms, govVaults, userData, clmUserData) {
  try {
    const userDataMap = new Map();
    const govUserDataMap = new Map();

    userData.items.forEach(item => {
      const id = item.beefy_id;
      const sumInvestorCounts = item.investor_counts.reduce((sum, count) => sum + count, 0);
      userDataMap.set(id, {
        id,
        sumInvestorCounts,
        investorCounts: item.investor_counts
      });
    });

    clmUserData.forEach(item => {
      const address = item.token_address.toLowerCase();
      const holders = item.holder_count;
      govUserDataMap.set(address, {
        address,
        holders: holders,
      });
    });

    const combinedUserData = [];

    const usersByChainAndType = {};
    const usersByPlatformAndType = {};

    // Helper function to update user counts
    const updateUserCounts = (obj, key, subKey, count) => {
      if (!obj[key]) {
        obj[key] = {};
      }
      if (obj[key][subKey]) {
        obj[key][subKey] += count;
      } else {
        obj[key][subKey] = count;
      }
    };

    // Process vaults
    vaults.forEach(vault => {
      const userData = userDataMap.get(vault.id);
      let type = 'vault';
      if (userData) {
        const { chain, platformId } = vault;
        if (vault.id !== 'bifi-vault' && vault.id.endsWith('-vault')) {
          type = 'clm';
        }
        
        const count = userData.sumInvestorCounts;

        updateUserCounts(usersByChainAndType, chain, type, count);
        updateUserCounts(usersByPlatformAndType, platformId, type, count);
      }
    });

    // Process CLMs
    clms.forEach(clm => {
      const baseId = clm.id;
      const vaultId = `${baseId}-vault`;
      const { chain, tokenProviderId } = clm;

      [baseId, vaultId].forEach(id => {
        const userData = userDataMap.get(id);
        if (userData) {
          const count = userData.sumInvestorCounts;
          const type ='clm';

          updateUserCounts(usersByChainAndType, chain, type, count);
          updateUserCounts(usersByPlatformAndType, tokenProviderId, type, count);
        }
      });
    });

    govVaults.forEach(item => {
      const address = item.earnContractAddress.toLowerCase();
      const chain = item.chain;
      const platformId = item.platformId;
      if (item.id.endsWith('-rp')) {
        const userData = govUserDataMap.get(address);
        if (userData) {
          const count = userData.holders;
          const type ='clm';

          updateUserCounts(usersByChainAndType, chain, type, count);
          updateUserCounts(usersByPlatformAndType, platformId, type, count);
        }
      }
    });

   // console.log('Users by chain and type:', usersByChainAndType);
   // console.log('Users by platform and type:', usersByPlatformAndType);

    // Process vaults
    vaults.forEach(vault => {
      if (userDataMap.has(vault.id)) {
        combinedUserData.push(userDataMap.get(vault.id));
      }
    });

    // Process CLMs
    clms.forEach(clm => {
      const baseId = clm.id;
      const rpId = `${baseId}-rp`;
      const vaultId = `${baseId}-vault`;

      [baseId, rpId, vaultId].forEach(id => {
        if (userDataMap.has(id)) {
          combinedUserData.push(userDataMap.get(id));
        }
      });
    });

   // console.log('Combined user data:', combinedUserData);
    return {combinedUserData, usersByChainAndType, usersByPlatformAndType};
  } catch (error) {
    console.error('Error fetching user data:', error);
    return [];
  }
}


export async function fetchVaultsByPlatformAndNetwork(vaults) {
  try {
    const vaultsByNetwork = {};
    const vaultsByPlatform = {};

    vaults.forEach(vault => {
      // Skip vaults with "status": "eol"
      if (vault.status === 'eol') return;
      if (vault.id.endsWith('-vault') && !vault.id.endsWith('bifi-vault')) return;

      // Count vaults by network
      if (vaultsByNetwork[vault.chain]) {
        vaultsByNetwork[vault.chain]++;
      } else {
        vaultsByNetwork[vault.chain] = 1;
      }

      // Count vaults by platform
      if (vaultsByPlatform[vault.platform]) {
        vaultsByPlatform[vault.platform]++;
      } else {
        vaultsByPlatform[vault.platform] = 1;
      }
    });

    return {
      byNetwork: vaultsByNetwork,
      byPlatform: vaultsByPlatform,
    };
  } catch (error) {
    console.error('Error fetching vaults data:', error);
    return { byNetwork: {}, byPlatform: {} };
  }
}

export async function fetchHistoricalData(historicalTvlData, historicalDefiTvlData) {
  try {
    const { fields, data } = historicalTvlData;
    const historicalData = {};

    Object.entries(data).forEach(([chain, values]) => {
      const capitalizedChain = chain.charAt(0).toUpperCase() + chain.slice(1);
      historicalData[capitalizedChain] = values.map(item => {
        const entry = {};
        fields.forEach((field, index) => {
          entry[field] = item[index];
        });
        return entry;
      });
    });

    // Add DeFi chain data
    historicalData['Total DeFi'] = historicalDefiTvlData.map(item => ({
      t: item.date,
      total: item.tvl,
      clm: 0,
      gov: 0,
      vault: 0
    }));

    console.log('Historical data:', historicalData);
    return historicalData;
  } catch (error) {
    console.error('Error fetching historical data:', error);
    return {};
  }
}

// Add this new function at the end of the file
export async function fetchAllData() {
  try {
    const [vaultsResponse, clmsResponse, govResponse, apyResponse, tvlResponse, userResponse, clmUserResponse, historicalDataResponse, historicalDefiResponse] = await Promise.all([
      axios.get('https://api.beefy.finance/vaults'),
      axios.get('https://api.beefy.finance/cow-vaults'),
      axios.get(' https://api.beefy.finance/gov-vaults'),
      axios.get('https://api.beefy.finance/apy/breakdown'),
      axios.get('https://api.beefy.finance/tvl'),
      axios.get('https://databarn.beefy.com/api/v1/beefy/investor-counts'),
      axios.get('https://balance-api.beefy.finance/api/v1/holders/counts/all'),
      axios.get('https://data.beefy.finance/api/v2/tvlByChains?bucket=1d_1Y'),
      axios.get('https://api.llama.fi/v2/historicalChainTvl')
    ]);

    const vaults = vaultsResponse.data;
    const clms = clmsResponse.data;
    const apyData = apyResponse.data;
    const tvlData = tvlResponse.data;
    const userData = userResponse.data;
    const clmUserData = clmUserResponse.data;
    const govVaults = govResponse.data;
    const historicalTvlData = historicalDataResponse.data;
    const historicalDefiTvlData = historicalDefiResponse.data;
    const clmData = await fetchClmData(vaults, clms);
    const tvlResult = await fetchTVLData(vaults, clms, tvlData);
    const almTVLResult = await fetchALMTVLData();
    const revenueResult = await fetchRevenueData(vaults, clms, apyData, tvlData);
    const vaultResult = await fetchVaultsByPlatformAndNetwork(vaults);
    const userResult = await fetchUserCount(vaults, clms, govVaults, userData, clmUserData);
    const historicalData = await fetchHistoricalData(historicalTvlData, historicalDefiTvlData);

    const combinedUserData = userResult.combinedUserData;
    const usersByChainAndType = userResult.usersByChainAndType;
    const usersByPlatformAndType = userResult.usersByPlatformAndType;

    return {
      clmData,
      tvlResult,
      almTVLResult,
      revenueResult,
      vaultResult,
      combinedUserData,
      usersByChainAndType,
      usersByPlatformAndType,
      historicalData,
      historicalDefiTvlData,
    };
  } catch (error) {
    console.error('Error fetching all data:', error);
    throw error;
  }
}
