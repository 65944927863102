import React, { useState, useEffect } from 'react';
import DashboardMetrics from '../DashboardMetrics/DashboardMetrics';
import OverviewChartContainer from '../OverviewChartContainer/OverviewChartContainer';
import CLMsChartContainer from '../CLMsChartContainer/CLMsChartContainer';
import TopRevenueIds from '../TopRevenueIds/TopRevenueIds';
import VaultsChartContainer from '../VaultsChartContainer/VaultsChartContainer';
import { fetchAllData } from '../../utils/dataFetching';
import { createColorMap } from '../../utils/chartUtils';
import './Dashboard.css';

function Dashboard({ page }) {
  const [clmData, setClmData] = useState(null);
  const [chainTVL, setChainTVL] = useState(null);
  const [platformTVL, setPlatformTVL] = useState(null);
  const [almTVLData, setAlmTVLData] = useState({});
  const [totalTVL, setTotalTVL] = useState(0);
  const [activeCLMs, setActiveCLMs] = useState(0);
  const [supportedChains, setSupportedChains] = useState(0);
  const [supportedPlatforms, setSupportedPlatforms] = useState(0);
  const [colorMap, setColorMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalClmRevenue, setTotalClmRevenue] = useState(0);
  const [revenueByChain, setRevenueByChain] = useState(null);
  const [revenueByPlatform, setRevenueByPlatform] = useState(null);
  const [clmRevenueByChain, setClmRevenueByChain] = useState(null);
  const [clmRevenueByPlatform, setClmRevenueByPlatform] = useState(null);
  const [topRevenueIds, setTopRevenueIds] = useState([]);
  const [allVaults, setAllVaults] = useState([]);
  const [allLegacy, setAllLegacy] = useState([]);
  const [totalVaults, setTotalVaults] = useState(0);
  const [vaultsByPlatformAndNetwork, setVaultsByPlatformAndNetwork] = useState(null);
  const [vaultChainTVL, setVaultChainTVL] = useState(null);
  const [vaultsTvl, setVaultsTvl] = useState(null);
  const [vaultsPlatformTvl, setVaultsPlatformTvl] = useState(null);
  const [vaultsSupportedPlatforms, setVaultsSupportedPlatforms] = useState(0);
  const [vaultsSupportedChains, setVaultsSupportedChains] = useState(0);
  const [totalChains, setTotalChains] = useState(0);
  const [totalPlatforms, setTotalPlatforms] = useState(0);
  const [uniqueChains, setUniqueChains] = useState(new Set());
  const [uniquePlatforms, setUniquePlatforms] = useState(new Set());
  const [combinedVaults, setCombinedVaults] = useState({});
  const [combinedRevenueByChain, setCombinedRevenueByChain] = useState({});
  const [combinedRevenueByPlatform, setCombinedRevenueByPlatform] = useState({});
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersByChainAndType, setUsersByChainAndType] = useState({});
  const [usersByPlatformAndType, setUsersByPlatformAndType] = useState({});
  const [totalUsersByType, setTotalUsersByType] = useState({});
  const [historicalData, setHistoricalData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allData = await fetchAllData();
        const { clmData, tvlResult, almTVLResult, revenueResult, vaultResult, usersByChainAndType, usersByPlatformAndType, historicalData } = allData;

        setClmData(clmData);
        setChainTVL(tvlResult.clmChainTVL);
        setVaultsTvl(tvlResult.vaultChainTVL);
        setVaultsPlatformTvl(tvlResult.vaultPlatformTVL);
        setVaultChainTVL(tvlResult.totalVaultTVL);
        setPlatformTVL(tvlResult.clmPlatformTVL);
        setTotalTVL(tvlResult.totalCLMTVL);

        const totalActiveCLMs = Object.values(clmData).reduce((sum, count) => sum + count, 0);
        setActiveCLMs(totalActiveCLMs);

        const numberOfChains = Object.keys(clmData).length;
        setSupportedChains(numberOfChains);

        const numberOfVaultChains = Object.keys(tvlResult.vaultChainTVL).length;
        setVaultsSupportedChains(numberOfVaultChains);

        const numberOfPlatforms = Object.keys(tvlResult.clmPlatformTVL).length;
        setSupportedPlatforms(numberOfPlatforms);

        const numberOfVaultPlatforms = Object.keys(tvlResult.vaultPlatformTVL).length;
        setVaultsSupportedPlatforms(numberOfVaultPlatforms);

        const combinedAlmTVLData = {
          beefy: { label: 'Beefy', tvl: tvlResult.totalCLMTVL },
          ...almTVLResult,
        };
        setAlmTVLData(combinedAlmTVLData);

        const newColorMap = createColorMap(
          clmData,
          tvlResult.clmChainTVL,
          tvlResult.clmPlatformTVL,
          combinedAlmTVLData
        );
        setColorMap(newColorMap);

        setRevenueByChain(revenueResult.revenueByChain);
        setRevenueByPlatform(revenueResult.revenueByPlatform);
        setClmRevenueByChain(revenueResult.clmRevenueByChain);
        setClmRevenueByPlatform(revenueResult.clmRevenueByPlatform);

        const totalClmRevenueValue = Object.values(revenueResult.clmRevenueByChain).reduce(
          (sum, value) => sum + value,
          0
        );
        setTotalClmRevenue(totalClmRevenueValue);

        const totalRevenueValue = Object.values(revenueResult.revenueByChain).reduce(
          (sum, value) => sum + value,
          0
        );
        setTotalRevenue(totalRevenueValue);

        setTopRevenueIds(revenueResult.topRevenueIds);
        setAllVaults(revenueResult.allVaults);
        setAllLegacy(revenueResult.allLegacy);
        setVaultsByPlatformAndNetwork(vaultResult);
        setTotalVaults(Object.values(vaultResult.byNetwork).reduce((sum, count) => sum + count, 0));

        // Combine clmData and vaultResult.byNetwork
        const combined = {};
        const allChains = new Set([...Object.keys(clmData), ...Object.keys(vaultResult.byNetwork)]);
        allChains.forEach(chain => {
          const normalizedChain = chain.toLowerCase();
          if (!combined[normalizedChain]) {
            combined[normalizedChain] = 0;
          }
          combined[normalizedChain] += (clmData[chain] || 0) + (vaultResult.byNetwork[chain] || 0);
        });
        setCombinedVaults(combined);

        const vaultChains = new Set(Object.keys(tvlResult.vaultChainTVL));
        const clmChains = new Set(Object.keys(clmData));
        const uniqueChains = new Set([...vaultChains, ...clmChains]);

        setUniqueChains(uniqueChains);
        setTotalChains(uniqueChains.size);

        // Calculate totalPlatforms
        const vaultPlatforms = new Set(Object.keys(tvlResult.vaultPlatformTVL));
        const clmPlatforms = new Set(Object.keys(tvlResult.clmPlatformTVL));
        const uniquePlatforms = new Set([...vaultPlatforms, ...clmPlatforms]);
        setUniquePlatforms(uniquePlatforms);
        setTotalPlatforms(uniquePlatforms.size);

        // Combine revenue data for chains
        const combinedChainRevenue = {};
        Object.keys({
          ...revenueResult.revenueByChain,
          ...revenueResult.clmRevenueByChain,
        }).forEach(chain => {
          combinedChainRevenue[chain] =
            (revenueResult.revenueByChain[chain] || 0) +
            (revenueResult.clmRevenueByChain[chain] || 0);
        });

        setCombinedRevenueByChain(combinedChainRevenue);

        // Combine revenue data for platforms
        const combinedPlatformRevenue = {};
        Object.keys({
          ...revenueResult.revenueByPlatform,
          ...revenueResult.clmRevenueByPlatform,
        }).forEach(platform => {
          combinedPlatformRevenue[platform] =
            (revenueResult.revenueByPlatform[platform] || 0) +
            (revenueResult.clmRevenueByPlatform[platform] || 0);
        });
        setCombinedRevenueByPlatform(combinedPlatformRevenue);

        // Calculate total users
        const totalUsersCount = Object.values(usersByChainAndType).reduce(
          (sum, chainData) => sum + Object.values(chainData).reduce((chainSum, count) => chainSum + count, 0),
          0
        );
        setTotalUsers(totalUsersCount);

        // Calculate total users by each type (vault and clm)
        const totalUsersByType = {
          vault: 0,
          clm: 0,
        };

        Object.values(usersByChainAndType).forEach(chainData => {
          Object.entries(chainData).forEach(([type, count]) => {
            if (totalUsersByType[type] !== undefined) {
              totalUsersByType[type] += count;
            }
          });
        });

        console.log('Total Users by Type:', totalUsersByType);
        setTotalUsersByType(totalUsersByType);

        setUsersByChainAndType(usersByChainAndType);
        setUsersByPlatformAndType(usersByPlatformAndType);

        console.log('Users by Chain and Type:', usersByChainAndType);
        console.log('Users by Platform and Type:', usersByPlatformAndType);

        setHistoricalData(historicalData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="dashboard">
      <DashboardMetrics
        page={page}
        totalTVL={totalTVL}
        vaultChainTVL={vaultChainTVL}
        activeCLMs={activeCLMs}
        supportedChains={supportedChains}
        supportedPlatforms={supportedPlatforms}
        supportedVaultPlatforms={vaultsSupportedPlatforms}
        supportedVaultChains={vaultsSupportedChains}
        totalRevenue={totalRevenue}
        totalClmRevenue={totalClmRevenue}
        totalVaults={totalVaults}
        totalChains={totalChains}
        totalPlatforms={totalPlatforms}
        totalUsers={totalUsers}
        totalUsersByType={totalUsersByType}
      />
      <div className="main-content">
        {page === 'overview' && (
          <>
            <OverviewChartContainer
              uniqueChains={uniqueChains}
              uniquePlatforms={uniquePlatforms}
              chainTVL={chainTVL}
              platformTVL={platformTVL}
              almTVLData={almTVLData}
              revenueByChain={combinedRevenueByChain}
              revenueByPlatform={combinedRevenueByPlatform}
              colorMap={colorMap}
              isLoading={isLoading}
              clmData={clmData}
              vaultsTvl={vaultsTvl}
              vaultsPlatformTvl={vaultsPlatformTvl}
              combinedVaults={combinedVaults}
              usersByChainAndType={usersByChainAndType}
              usersByPlatformAndType={usersByPlatformAndType}
              historicalData={historicalData}
            />
            <TopRevenueIds allVaults={[...allVaults, ...allLegacy]} currentPage={page} />
          </>
        )}
        {page === 'clms' && (
          <>
            <CLMsChartContainer
              clmData={clmData}
              chainTVL={chainTVL}
              platformTVL={platformTVL}
              almTVLData={almTVLData}
              revenueByChain={clmRevenueByChain}
              revenueByPlatform={clmRevenueByPlatform}
              colorMap={colorMap}
              isLoading={isLoading}
              usersByChainAndType={usersByChainAndType}
              usersByPlatformAndType={usersByPlatformAndType}
            />
            <TopRevenueIds allVaults={allVaults} currentPage={page} />
          </>
        )}
        {page === 'vaults' && vaultsByPlatformAndNetwork && (
          <>
            <VaultsChartContainer
              vaultChainTVL={vaultsTvl}
              platformTVL={vaultsPlatformTvl}
              revenueByChain={revenueByChain}
              revenueByPlatform={revenueByPlatform}
              vaultsByPlatformAndNetwork={vaultsByPlatformAndNetwork}
              colorMap={colorMap}
              isLoading={isLoading}
              totalVaults={totalVaults}
              usersByChainAndType={usersByChainAndType}
              usersByPlatformAndType={usersByPlatformAndType}
            />
            <TopRevenueIds allVaults={allLegacy} currentPage={page} />
          </>
        )}
      </div>
      {isLoading && <div className="loading-overlay">Loading...</div>}
    </div>
  );
}

export default Dashboard;
