import React from 'react';
import ChartComponent from '../Chart/Chart';
import HistoricalTVLChart from '../HistoricalTVLChart/HistoricalTVLChart';
import {
  formatCurrency,
  formatNumber,
  chartColors,
  verticalChartOptions,
  horizontalChartOptions,
  isMobile,
} from '../../utils/chartUtils';
import '../ChartContainer.css';

function OverviewChartContainer({
  uniqueChains,
  uniquePlatforms,
  chainTVL,
  platformTVL,
  almTVLData,
  revenueByChain,
  revenueByPlatform,
  colorMap,
  isLoading,
  clmData,
  vaultsTvl,
  vaultsPlatformTvl,
  vaultIds,
  clmIds,
  combinedVaults,
  usersByChainAndType,
  usersByPlatformAndType,
  historicalData,
}) {
  const createChartData = (data, colors, isCurrency = true, aggregateThreshold = 0) => {
    if (!data || typeof data !== 'object') {
      console.log('Invalid data for createChartData:', data);
      return { labels: [], datasets: [] };
    }

    let sortedEntries = Object.entries(data).sort((a, b) => b[1] - a[1]);

    if (aggregateThreshold > 0) {
      let otherSum = 0;
      sortedEntries = sortedEntries.reduce((acc, [key, value]) => {
        if (value < aggregateThreshold) {
          otherSum += value;
        } else {
          acc.push([key, value]);
        }
        return acc;
      }, []);

      if (otherSum > 0) {
        sortedEntries.push(['Other', otherSum]);
      }

      sortedEntries.sort((a, b) => b[1] - a[1]);
    }

    const sortedLabels = sortedEntries.map(
      ([key]) => key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()
    );
    const sortedData = sortedEntries.map(([, value]) => value);

    return {
      labels: sortedLabels,
      datasets: [
        {
          label: isCurrency ? 'Value' : 'Count',
          data: sortedData,
          backgroundColor: sortedLabels.map(
            key => colors[key] || chartColors[sortedLabels.indexOf(key) % chartColors.length]
          ),
          borderColor: 'rgba(255, 255, 255, 0.5)',
          borderWidth: 1,
        },
      ],
    };
  };

  const createVaultsAndCLMsData = () => {
    return createChartData(combinedVaults, colorMap, false);
  };

  const createTVLPerChainData = () => {
    const combinedTVL = {};
    uniqueChains.forEach(chain => {
      const clmTVL = chainTVL[chain] || 0;
      const vaultTVL = vaultsTvl[chain] || 0;
      combinedTVL[chain] = clmTVL + vaultTVL;
    });
    return createChartData(combinedTVL, colorMap, true, 110000);
  };

  const createTVLPerPlatformData = () => {
    const combinedTVL = {};
    uniquePlatforms.forEach(platform => {
      const clmTVL = platformTVL[platform] || 0;
      const vaultTVL = vaultsPlatformTvl[platform] || 0;
      combinedTVL[platform] = clmTVL + vaultTVL;
    });
    return createChartData(combinedTVL, colorMap, true, 800000);
  };

  const vaultsAndCLMsOptions = isMobile() ? horizontalChartOptions : {
    ...verticalChartOptions,
    indexAxis: 'x',
    scales: {
      x: {
        ...verticalChartOptions.scales.x,
        grid: {
          display: false,
        },
        ticks: {
          ...verticalChartOptions.scales.x.ticks,
          maxRotation: 45,
          minRotation: 45,
          font: function (context) {
            const width = context.chart.width;
            if (width < 400) {
              return { size: 7 };
            } else if (width < 600) {
              return { size: 9 };
            } else {
              return { size: 10 };
            }
          },
        },
      },
      y: {
        ...verticalChartOptions.scales.y,
        beginAtZero: true,
        ticks: {
          ...verticalChartOptions.scales.y.ticks,
          callback: value => formatNumber(value),
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      ...verticalChartOptions.plugins,
      tooltip: {
        ...verticalChartOptions.plugins.tooltip,
        callbacks: {
          label: context => {
            const count = context.parsed.y;
            return `${formatNumber(count)} Vaults and CLMs`;
          },
        },
      },
    },
  };

  const tvlOptions = isMobile() ? horizontalChartOptions : {
    ...verticalChartOptions,
    indexAxis: 'x',
    scales: {
      x: {
        ...verticalChartOptions.scales.x,
        grid: {
          display: false,
        },
        ticks: {
          ...verticalChartOptions.scales.x.ticks,
          maxRotation: 45,
          minRotation: 45,
          font: function (context) {
            const width = context.chart.width;
            if (width < 400) {
              return { size: 7 };
            } else if (width < 600) {
              return { size: 9 };
            } else {
              return { size: 10 };
            }
          },
        },
      },
      y: {
        ...verticalChartOptions.scales.y,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          ...verticalChartOptions.scales.y.ticks,
          callback: value => formatCurrency(value),
        },
      },
    },
    plugins: {
      ...verticalChartOptions.plugins,
      tooltip: {
        ...verticalChartOptions.plugins.tooltip,
        callbacks: {
          label: context => {
            const value = context.parsed.y;
            return `${formatCurrency(value)}`;
          },
        },
      },
    },
  };

  const createUsersByChainData = () => {
    const combinedUsers = {};
    Object.entries(usersByChainAndType).forEach(([chain, types]) => {
      combinedUsers[chain] = Object.values(types).reduce((sum, count) => sum + count, 0);
    });
    console.log('combinedUsers', combinedUsers);
    return createChartData(combinedUsers, colorMap, false, 100);
  };

  const createUsersByPlatformData = () => {
    if (!usersByPlatformAndType) {
      console.log('usersByPlatformAndType is undefined or null');
      return { labels: [], datasets: [] };
    }
    const combinedUsers = {};
    Object.entries(usersByPlatformAndType).forEach(([platform, types]) => {
      combinedUsers[platform] = Object.values(types).reduce((sum, count) => sum + count, 0);
    });
    return createChartData(combinedUsers, colorMap, false, 1000);
  };

  const usersOptions = isMobile() ? horizontalChartOptions : {
    ...vaultsAndCLMsOptions,
    plugins: {
      ...vaultsAndCLMsOptions.plugins,
      tooltip: {
        ...vaultsAndCLMsOptions.plugins.tooltip,
        callbacks: {
          label: context => {
            const count = context.parsed.y;
            return `${formatNumber(count)} Users`;
          },
        },
      },
    },
  };

  return (
    <div className="charts-container">
      <HistoricalTVLChart historicalData={historicalData} colorMap={colorMap} />
      <ChartComponent
        title="Vaults and CLMs per Chain"
        data={createVaultsAndCLMsData()}
        options={vaultsAndCLMsOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="TVL by Chain"
        data={createTVLPerChainData()}
        options={tvlOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="TVL by Platform"
        data={createTVLPerPlatformData()}
        options={tvlOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="Revenue by Chain"
        data={createChartData(revenueByChain, colorMap, true, 2000)}
        options={tvlOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="Revenue by Platform"
        data={createChartData(revenueByPlatform, colorMap, true, 10000)}
        options={tvlOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="Users by Chain"
        data={createUsersByChainData()}
        options={usersOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="Users by Platform"
        data={createUsersByPlatformData()}
        options={usersOptions}
        isLoading={isLoading}
      />
    </div>
  );
}

export default OverviewChartContainer;
